import { Drawer } from 'antd';
import MenuList from '../MenuList';

function DrawerMenu(props: { isDrawerVisible: boolean; setDrawerVisible: (val: boolean) => void }) {
  const { isDrawerVisible, setDrawerVisible } = props;
  return (
    <Drawer
      closable
      destroyOnClose
      placement="left"
      open={isDrawerVisible}
      onClose={() => setDrawerVisible(false)}
      style={{ height: '100vh', overflow: 'auto', width: 300 }}
      styles={{ body: { background: '#000' } }}
    >
      <MenuList collapsed={false} />
    </Drawer>
  );
}

export default DrawerMenu;
