/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import Card from '@/components/Card';
import Table from '@/components/Table';
import Footer from '@/components/Table/Footer';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { featureFlag } from '@/utils/featureFlags';
import { IAsset, IPosition } from './interface';

interface IAssetClass {
  data: IPosition[];
  isLoading: boolean;
  title: string;
  renderedBy?: string;
}

interface IAssetClassFooter {
  pageData: any;
  columns: any;
  sumFields: { field: string; displayFormat?: string; value: string }[];
  label: string;
}
function renderFooter({ pageData, columns, sumFields, label }: IAssetClassFooter) {
  return <Footer pageData={pageData} label={label} sumFields={sumFields} columns={columns} />;
}

function generatePrimaryColumns(isPending: boolean, renderedBy: string = '') {
  const primaryColumns: ColumnsType<IAsset> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IAsset) =>
        featureFlag['page:private_investment_details'] ? (
          <Link to={`/private-market/${record.id}`}>{text}</Link>
        ) : (
          <span>{text}</span>
        ),
      width: 200,
    },
    {
      title: 'COMMITTED',
      dataIndex: 'committed_display',
      key: 'committed_display',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'CALLED',
      dataIndex: 'called_display',
      key: 'called_display',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'REMAINING',
      dataIndex: 'remaining_display',
      key: 'remaining_display',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'DISTRIBUTED',
      dataIndex: 'distributed_display',
      key: 'distributed_display',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'PAID TO DATE',
      dataIndex: 'paid_display',
      key: 'paid_display',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'PENDING',
      dataIndex: 'pending_display',
      key: 'pending_display',
      hidden: !isPending,
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'FMV',
      dataIndex: 'efv_display',
      key: 'efv_display',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'DPI',
      dataIndex: 'dpi',
      key: 'dpi',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'TVPI',
      dataIndex: 'tvpi',
      key: 'tvpi',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'NET IRR',
      dataIndex: 'net_irr_display',
      key: 'net_irr_display',
      align: 'center' as const,
      width: 100,
    },
  ].filter((column) => !column.hidden);
  return primaryColumns;
}

// const secondaryColumns = [
//   {
//     title: 'DPI',
//     dataIndex: 'dpi',
//     key: 'dpi',
//   },
//   {
//     title: 'TVPI',
//     dataIndex: 'tvpi',
//     key: 'tvpi',
//   },
//   {
//     title: 'NET IRR',
//     dataIndex: 'net_irr_display',
//     key: 'net_irr_display',
//   },
// ];

const primaryFooterSumFields = [
  {
    field: 'committed_display',
    displayFormat: 'currency',
    value: 'committed_raw',
    align: 'center',
  },
  { field: 'called_display', displayFormat: 'currency', value: 'called_raw', align: 'center' },
  {
    field: 'distributed_display',
    displayFormat: 'currency',
    value: 'distributed_raw',
    key: 'distributed_raw',
    align: 'center',
  },
  { field: 'efv_display', displayFormat: 'currency', value: 'efv_raw', align: 'center' },
  { field: 'paid_display', displayFormat: 'currency', value: 'paid_raw', align: 'center' },
  { field: 'pending_display', displayFormat: 'currency', value: 'pending_raw', align: 'center' },
  {
    field: 'remaining_display',
    displayFormat: 'currency',
    value: 'remaining_raw',
    align: 'center',
  },
  { field: 'tvpi', value: '', align: 'center' },
  { field: 'dpi', value: '', align: 'center' },
  { field: 'net_irr_display', displayFormat: 'percent', value: '', align: 'center' },
];

// const secondaryFooterSumFields = [
//   { field: 'tvpi', value: 'tvpi' },
//   { field: 'dpi', value: 'dpi' },
//   { field: 'net_irr_display', displayFormat: 'percent', value: 'net_irr_raw' },
// ];

function AssetClass(props: IAssetClass) {
  const { data, isLoading, title, renderedBy } = props;
  const [primaryColumns, setPrimaryColumns] = useState<ColumnsType<any>>([]);
  const isPending = data?.some((item) => Boolean(item.pending_raw) && item.pending_raw > 0);
  useEffect(() => {
    const generatedColumns = generatePrimaryColumns(isPending, renderedBy);
    setPrimaryColumns(generatedColumns);
  }, [isPending]);
  return (
    <Card title={title}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Table
            className="bg-header-white"
            isCardLayout={false}
            dataSource={data}
            columns={primaryColumns}
            pagination={false}
            globalSearch={false}
            rowKey={(record) => record.name}
            isLoading={isLoading}
            summary={(pageData) =>
              data.length > 0 &&
              renderFooter({
                pageData,
                columns: primaryColumns,
                sumFields: primaryFooterSumFields,
                label: 'Total',
              })
            }
            scroll={{ x: 'max-content' }}
            noDataMessage={
              renderedBy === 'Private Market Overview'
                ? `Please schedule time with your advisor to explore ${title.replace(
                    /investments?/i,
                    ''
                  )} Investment opportunities.`
                : undefined
            }
          />
        </Col>
        {/* <Col xs={24} sm={24} md={8} lg={12}>
          {!isLoading && (
            <Table
              isLoading={isLoading}
              className="bg-header-white"
              rowClassName={(record, index) =>
                // eslint-disable-next-line no-nested-ternary
                index % 2 === 0
                  ? 'table-row-light'
                  : record.name === 'Total'
                  ? 'table-row-white '
                  : 'table-row-dark'
              }
              isCardLayout={false}
              dataSource={data}
              columns={secondaryColumns}
              pagination={false}
              globalSearch={false}
              rowKey={(record) => record.name}
              summary={(pageData) =>
                data.length > 0 &&
                renderFooter({
                  pageData,
                  columns: secondaryColumns,
                  sumFields: secondaryFooterSumFields,
                  label: '',
                })
              }
              scroll={{ x: 'max-content' }}
            />
          )}
        </Col> */}
      </Row>
    </Card>
  );
}

AssetClass.defaultProps = {
  renderedBy: '',
};
export default AssetClass;
