import { Layout } from 'antd';
import { useScreenSize } from '@/hooks/useScreenSize';
import MenuList from '../MenuList';

const { Sider } = Layout;
function SideBar(props: { collapsed: boolean }) {
  const { isSmallScreen } = useScreenSize();
  const { collapsed } = props;

  // const getMenuOpenKeys = (pathname: string) => {
  //   // openKeys based on the URL
  //   switch (pathname) {
  //     case '/private-market':
  //     case '/updates':
  //     case '/checklist':
  //       return ['Private Market'];

  //     case '/public-market':
  //       return ['Public Market'];
  //     case '/performance':
  //     case '/benchmarks':
  //       return ['Performance'];
  //     default:
  //       return [];
  //   }
  // };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ position: 'fixed', height: '100vh', fontSize: '1.2em', overflow: 'auto' }}
      width={isSmallScreen ? 0 : 200}
      collapsedWidth={isSmallScreen ? 0 : 100}
    >
      <MenuList collapsed={collapsed} />
    </Sider>
  );
}
export default SideBar;
