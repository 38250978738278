export const mockPerformanceSummaryData = {
  periods: [
    { label: '3 Month', Period: '3_month' },
    { label: 'YTD', Period: 'ytd' },
    { label: '1 Year*', Period: '1_year' },
    { label: '3 Year*', Period: '3_year' },
    { label: '5 Year*', Period: '5_year' },
    { label: '10 Year*', Period: '10_year' },
  ],
  summaryData: [
    {
      name: 'Gross Of BIP Advisory Fees',
      marketValue: '4280.0000',
      percentageOfTotal: 100,
      '3_month': 0.8,
      ytd: 0.8,
      '1_year': 0.8,
      '3_year': 0.8,
      '5_year': 0.8,
      '10_year': 0.1,
      inception: 0.8,
      inceptionDate: '2024/10/10',
      isTotalizedRow: true,
    },
    {
      name: 'Net Of BIP Advisory Fees',
      marketValue: '4280.0000',
      percentageOfTotal: 100,
      '3_month': -0.1,
      ytd: 0.5,
      '1_year': 0.6,
      '3_year': 0.7,
      '5_year': 0.8,
      '10_year': 0.9,
      inception: 1.0,
      inceptionDate: '2024/10/10',
      isTotalizedRow: true,
    },
    {
      name: 'Public (Gross Of BIP Advisory Fees)',
      isTitle: true,
    },
    {
      name: 'Equities and Fixed Income',
      marketValue: 1930,
      percentageOfTotal: 45.09345794392524,
      '3_month': 0.2,
      ytd: 0.3,
      '1_year': 0.4,
      '3_year': 0.5,
      '5_year': 0.6,
      '10_year': 0.7,
      inception: 0.8,
      inceptionDate: '2024/10/10',
    },
    {
      name: 'Hedged Equity',
      marketValue: 210,
      percentageOfTotal: 4.906542056074766,
      '3_month': 0.1,
      ytd: 0.2,
      '1_year': 0.3,
      '3_year': 0.4,
      '5_year': 0.5,
      '10_year': 0.6,
      inception: 0.7,
      inceptionDate: '2024/10/10',
    },
    {
      name: 'Hedged Yield',
      marketValue: 220,
      percentageOfTotal: 5.14018691588785,
      '3_month': 0.3,
      ytd: 0.4,
      '1_year': 0.5,
      '3_year': 0.6,
      '5_year': 0.7,
      '10_year': 0.8,
      inception: 0.9,
      inceptionDate: '2024/10/10',
    },
    {
      name: 'Public Total',
      marketValue: 2360,
      percentageOfTotal: 55.140186915887845,
      '3_month': 0.4,
      ytd: 0.5,
      '1_year': 0.6,
      '3_year': 0.7,
      '5_year': 0.8,
      '10_year': 0.9,
      inception: 1.0,
      inceptionDate: '2024/10/10',
      isTotalizedRowWithBorder: true,
    },
    {
      name: 'Private (Gross Of BIP Advisory Fees)',
      isTitle: true,
    },
    {
      name: 'Equities**',
      marketValue: '280.0000',
      percentageOfTotal: 6.5420560747663545,
      '3_month': 0.5,
      ytd: 0.6,
      '1_year': 0.7,
      '3_year': 0.8,
      '5_year': 0.9,
      '10_year': 1.0,
      inception: 1.1,
      inceptionDate: '2024/10/10',
    },
    {
      name: 'Fixed Income**',
      marketValue: '300.0000',
      percentageOfTotal: 7.009345794392523,
      '3_month': 0.6,
      ytd: 0.7,
      '1_year': 0.8,
      '3_year': 0.9,
      '5_year': 1.0,
      '10_year': 1.1,
      inception: 1.2,
      inceptionDate: '2024/10/10',
    },
    {
      name: 'Real Estate**',
      marketValue: '290.0000',
      percentageOfTotal: 6.775700934579438,
      '3_month': 0.7,
      ytd: 0.8,
      '1_year': 0.9,
      '3_year': 1.0,
      '5_year': 1.1,
      '10_year': 1.2,
      inception: 1.3,
      inceptionDate: '2024/10/10',
    },
    {
      name: 'Private Total',
      marketValue: 870,
      percentageOfTotal: 20.327102803738313,
      '3_month': 0.8,
      ytd: 0.9,
      '1_year': 1.0,
      '3_year': 1.1,
      '5_year': 1.2,
      '10_year': 1.3,
      inception: 1.4,
      inceptionDate: '2024/10/10',
      isTotalizedRowWithBorder: true,
    },
  ],
  asOfReturnsDate: '2024-12-01',
};
