import { useScreenSize } from '@/hooks/useScreenSize';
import { ReactNode } from 'react';

interface ICardHeader {
  title: string;
  renderToolbar: ReactNode;
  iconTitle?: ReactNode;
  subTitle?: string;
}
function CardHeader(props: ICardHeader) {
  const { isSmallScreen } = useScreenSize();
  const { title, renderToolbar, iconTitle, subTitle } = props;
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <h3 style={{ fontWeight: 'bold', margin: 0, fontSize: isSmallScreen ? 12 : 16 }}>
          {iconTitle && <span style={{ marginRight: 10, marginTop: -10 }}>{iconTitle}</span>}
          <span
            style={{
              alignSelf: 'center',
              whiteSpace: 'wrap',
            }}
          >
            {title}
          </span>
        </h3>
        {renderToolbar && renderToolbar}
      </div>
      {subTitle && <p style={{ fontSize: 14 }}>{subTitle}</p>}
    </>
  );
}
CardHeader.defaultProps = {
  iconTitle: '',
  subTitle: '',
};
export default CardHeader;
