import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store';
import { getprivateMarket } from '@/services/privateMarketService';
import { useEffect } from 'react';
import CustomBreadcrumb from '@/components/Breadcrumb';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import PrivateMarketSummary from './PrivateMarketSummary';
import BDCPositions from './BdcPositions';
import AssetClass from './AssetClass';
import { IPortfolio } from './interface';

interface IPrivateMarket extends IPortfolio {
  isGetPrivateMarketLoading: boolean;
}

function PrivateMarket() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getprivateMarket());
  }, [dispatch]);

  const { equity, debt, real_estate, isGetPrivateMarketLoading }: IPrivateMarket = useSelector(
    (state: AppState) => state.privateMarket
  );

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <CustomHelmet title="Private Markets Overview" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: 'Private Markets Overview',
          },
        ]}
      />
      <PrivateMarketSummary
        data={{ equity, debt, real_estate }}
        isLoading={isGetPrivateMarketLoading}
      />
      <AssetClass
        title="Equity - Traditional Investments"
        data={equity?.positions || []}
        isLoading={isGetPrivateMarketLoading}
        renderedBy="Private Market Overview"
      />
      <BDCPositions
        title="Equity - BDC Investments"
        renderedBy="Private Market Overview"
        data={equity?.bdc_positions || []}
        isLoading={isGetPrivateMarketLoading}
      />
      <AssetClass
        title="Fixed Income - Traditional Investments"
        data={debt?.positions || []}
        isLoading={isGetPrivateMarketLoading}
        renderedBy="Private Market Overview"
      />
      <BDCPositions
        title="Fixed Income - BDC Investments"
        renderedBy="Private Market Overview"
        data={debt?.bdc_positions || []}
        isLoading={isGetPrivateMarketLoading}
      />
      <AssetClass
        title="Real Estate"
        data={real_estate?.positions || []}
        isLoading={isGetPrivateMarketLoading}
        renderedBy="Private Market Overview"
      />
    </Space>
  );
}
export default PrivateMarket;
