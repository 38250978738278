import { Datum } from '@ant-design/charts';
import { Pie, Tooltip } from '@ant-design/plots';
import { dollarCurrencyFormat } from '@/utils/helper';
import Card from '@/components/Card';
import { Empty, Skeleton, Typography } from 'antd';
import { useScreenSize } from '@/hooks/useScreenSize';

interface IData {
  type: string;
  value: number;
  tooltipValue?: string;
}

const LegendForMobileView = ({ data, chartColors }: { data: IData[]; chartColors: string[] }) => (
  <>
    {data.map((item, i) => (
      <Typography.Paragraph key={item.type} style={{ textAlign: 'left', width: '100%', margin: 0 }}>
        <span
          style={{
            display: 'inline-block',
            width: 10,
            height: 10,
            backgroundColor: chartColors[i],
            borderRadius: '50%',
            marginRight: 5,
          }}
        />
        {item.type}
      </Typography.Paragraph>
    ))}
  </>
);
export interface IPieChart {
  data: IData[];
  isCardLayout?: boolean;
  title?: string;
  chartColors: string[];
  isLoading?: boolean;
  tooltipCurrencyFormat?: boolean;
  showCustomPercent?: boolean;
  legendFontSize?: number;
}

function PieChart(props: IPieChart) {
  const { isSmallScreen } = useScreenSize();
  const {
    data,
    isCardLayout,
    title = '',
    chartColors,
    isLoading = false,
    tooltipCurrencyFormat = false,
    legendFontSize = 10,
    // showCustomPercent,
  } = props;
  // check atleast one value is greater than zero
  // const valueExist = data.some((item) => item.value > 0);

  const tooltipValueKeyExist = data.some((obj: IData) =>
    Object.prototype.hasOwnProperty.call(obj, 'tooltipValue')
  );

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: chartColors,
    radius: 0.8,
    legend: isSmallScreen
      ? (false as unknown as undefined)
      : {
          position: 'right' as 'right',
          itemName: {
            style: { fontSize: legendFontSize },
          },
        },
    tooltip: {
      customContent(_, items: Datum) {
        if (items && items.length) {
          const { type, value } = items?.[0]?.data ?? {};
          // eslint-disable-next-line no-nested-ternary
          const tooltipValue = tooltipCurrencyFormat
            ? dollarCurrencyFormat(value)
            : tooltipValueKeyExist
            ? data.find((item) => item.type === type)?.tooltipValue
            : value;

          return `<div style="padding: 3px;font-weight:bold; margin:3px">${type} - ${tooltipValue}</div>`;
        }
        return '';
      },
    } as Tooltip,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    label: null as any, // Hide label
    /* label: {
      type: 'inner',
      offset: '-30%',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      content: function content(_ref: any) {
        const { value, percent } = _ref;

        const displayValue = valueExist ? value || '' : 0;
        const customPercentage = showCustomPercent
          ? displayValue
          : `${(percent * 100).toFixed(0)} %`;
        return `${customPercentage}`;
        // return percent ? ''.concat((percent * 100).toFixed(0), '%') : '';

        // return ''.concat(_ref.value, '%');
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    }, */
    interactions: [{ type: 'element-active' }],
  };
  // eslint-disable-next-line no-nested-ternary
  return isCardLayout ? (
    <Card title={title} isLoading={isLoading}>
      {Array.isArray(data) && data.length > 0 && data.some((item) => item.value !== 0) ? (
        <>
          <Pie {...config} />
          {isSmallScreen && <LegendForMobileView data={data} chartColors={chartColors} />}
        </>
      ) : (
        <Empty />
      )}
    </Card>
  ) : // eslint-disable-next-line no-nested-ternary
  isLoading ? (
    <Skeleton active style={{ padding: 10 }} />
  ) : Array.isArray(data) && data.length > 0 && data.some((item) => item.value !== 0) ? (
    <>
      <Pie {...config} />
      {isSmallScreen && <LegendForMobileView data={data} chartColors={chartColors} />}
    </>
  ) : (
    <Empty />
  );
}

PieChart.defaultProps = {
  isCardLayout: false,
  title: '',
  isLoading: false,
  tooltipCurrencyFormat: false,
  showCustomPercent: false,
  legendFontSize: 10,
};
export default PieChart;
