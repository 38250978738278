import './performanceSummary.css';
import React from 'react';
import { Space, TableColumnsType } from 'antd';
import Table from '@/components/Table';
import { dollarCurrencyFormat, getPreviousBusinessDate } from '@/utils/helper';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';

import { ColumnsType } from 'antd/es/table';
import CustomBreadcrumb from '@/components/Breadcrumb';
import CustomHelmet from '@/components/Helmet/CustomHelmet';

interface DataType {
  name: string;
  marketValue: number | null;
  percentageOfTotal: number | null;
  '3M': number | null;
  YTD: number | null;
  '1Y': number | null;
  '3Y': number | null;
  '5Y': number | null;
  '10Y': number | null;
  inception: number | null;
  inceptionDate: string | null;
  isTitle?: boolean;
  isTotalizedRow?: boolean;
  isTotalizedRowWithBorder?: boolean;
}

const sharedOnCell = (record: DataType, index?: number) => {
  if (record.isTitle) {
    return { colSpan: 0 };
  }

  return {};
};

function calculateTotalColSpan(columns: ColumnsType<DataType>): number {
  let totalColSpan = 0;

  function traverse(columnList: ColumnsType<DataType>) {
    columnList.forEach((column) => {
      if ('children' in column) {
        traverse(column.children as ColumnsType<DataType>);
      } else {
        totalColSpan += 1;
      }
    });
  }

  traverse(columns);
  return totalColSpan;
}
const timePeriodValueRender = (value: string) => {
  if (value === null || value === undefined) {
    return <span>-.--%</span>;
  }

  const sign = Math.sign(Number(value));
  const val = Number(value) * 100;
  if (sign < 0) {
    return <span style={{ color: 'red' }}>({Math.abs(val)?.toFixed(2)}%)</span>;
  }
  return <span>{val?.toFixed(2)}%</span>;
};

interface PerformanceSummaryV2Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  summaryData: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  periods: any[];

  asOfReturnsDate: string;

  isLoading: boolean;
}

const PerformanceSummaryV2: React.FC<PerformanceSummaryV2Props> = ({
  summaryData,
  periods,
  asOfReturnsDate,
  isLoading,
}) => {
  const rowClassName = (record: DataType, index: number) => {
    if (record.isTotalizedRowWithBorder) {
      return `row-top-border total-calculated`;
    }
    if (record.isTotalizedRow) {
      return `total-calculated`;
    }
    if (record.isTitle) {
      return 'full-width-column';
    }
    return `custom-row ${index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}`;
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: `Current Allocation (As of ${moment(getPreviousBusinessDate()).format('MM/DD/YYYY')})`,
      children: [
        {
          title: 'Total',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          render: (text: string, record) => (record.isTitle ? <strong>{text}</strong> : text),
          onCell: (record, index) => ({
            colSpan: record.isTitle ? calculateTotalColSpan(columns) : 1,
          }),
        },
        {
          title: 'Market Value',
          dataIndex: 'marketValue',
          key: 'marketValue',
          onCell: sharedOnCell,
          render: (text: number) => dollarCurrencyFormat(text),
        },
        // {
        //   title: '% Of Total',
        //   dataIndex: 'percentageOfTotal',
        //   key: 'percentageOfTotal',
        //   onCell: sharedOnCell,
        //   render: (value: number) => <span>{value?.toFixed(2)}%</span>,
        // },
      ],
    },
    {
      title: `Returns (As of ${moment(asOfReturnsDate).format('MM/DD/YYYY')})`,
      children: [
        ...periods.map((period: { label: string; Period: string }) => ({
          title: period.label,
          key: period.Period,
          dataIndex: period.Period,
          onCell: sharedOnCell,
          render: (text: string, record: DataType) =>
            // if (period.Period === 'inception') {
            //   return (
            //     <>
            //       {timePeriodValueRender(text)}
            //       <span>
            //         {record?.inceptionDate
            //           ? ` (${moment(record.inceptionDate).format('MM/YY')})`
            //           : ' (--/--)'}
            //       </span>
            //     </>
            //   );
            // }
            timePeriodValueRender(text),
        })),
        {
          title: 'Since Inception(MM/YY)*',
          dataIndex: 'inception',
          key: 'inception',
          onCell: sharedOnCell,
          render: (text: string, record) => (
            <>
              {timePeriodValueRender(text)}
              <span>
                {record?.inceptionDate
                  ? ` (${moment(record.inceptionDate).format('MM/YY')})`
                  : ' (--/--)'}
              </span>
            </>
          ),
        },
      ],
    },
  ];

  return (
    <Space size="small" direction="vertical">
      <CustomHelmet title="Performance Summary" />
      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: 'Performance Summary',
          },
        ]}
      />
      <Table
        title="Performance Summary"
        globalSearch={false}
        columns={columns}
        dataSource={summaryData}
        bordered={true}
        pagination={false}
        rowClassName={rowClassName}
        rowKey={(record) => record.name}
        isLoading={isLoading}
      />
      <Space size="small" direction="vertical">
        <Paragraph>
          * Annualized <br />
          Public and Private sub-asset class total return data may include return data for assets
          that were not part of your portfolio account for the entire period of return measurement,
          thus having a potential impact on returns that is not readily apparent. Please consult
          your BIP Personal Wealth Advisor in the event you have questions concerning assets that
          may not have been included in your portfolio account for the entire period of measurement,
          and their effect, if any, on sub-asset class and household total returns.
        </Paragraph>

        <Paragraph>
          ** As of the most recent information provided by the investment sponsors and is for
          information purposes only. To view the date of the most recent value of each private
          investment, navigate to "Private Investments".
        </Paragraph>

        <Paragraph>
          PLEASE REFER TO THE <Link to="/disclosures">DISCLOSURES PAGE</Link> FOR ADDITIONAL
          INFORMATION ABOUT YOUR PUBLIC AND PRIVATE MARKET INVESTMENTS.
        </Paragraph>
      </Space>
    </Space>
  );
};

export default PerformanceSummaryV2;
