import { useScreenSize } from '@/hooks/useScreenSize';
import {
  BarChartOutlined,
  BookOutlined,
  CheckSquareOutlined,
  DesktopOutlined,
  FileOutlined,
  MessageOutlined,
  PieChartFilled,
  TeamOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function MenuList(props: { collapsed: boolean }) {
  const { collapsed } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(
    location?.pathname === '/' || location?.pathname === '' ? '/dashboard' : location?.pathname
  );
  // On Changing the route, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  // Define all parent menu keys to expand by default
  const defaultOpenKeys = ['Private Market', 'Public Market', 'Performance'];
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);
  const { isSmallScreen } = useScreenSize();

  useEffect(() => {
    if (location) {
      if (activeKey !== location?.pathname) {
        setActiveKey(location?.pathname);
      }
    }
  }, [location, activeKey]);

  const onOpenChange = (key: string[]) => {
    setOpenKeys(key);
  };
  useEffect(() => {
    if (collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(defaultOpenKeys);
    }
  }, [collapsed]);
  return (
    <>
      <div className="logo" style={isSmallScreen ? { height: 40, margin: 20 } : {}} />
      <Menu
        theme="dark"
        mode="inline"
        style={isSmallScreen ? { height: '100vh', marginBottom: 100 } : {}}
        selectedKeys={[activeKey]}
        // eslint-disable-next-line no-nested-ternary
        openKeys={
          // eslint-disable-next-line no-nested-ternary
          isSmallScreen ? defaultOpenKeys : collapsed ? [] : openKeys
        }
        // defaultOpenKeys={defaultOpenKeys}
        onOpenChange={onOpenChange}
        items={[
          {
            key: '/dashboard',
            icon: <PieChartFilled />,
            label: 'My Allocation',
            onClick: () => navigate('/dashboard'),
          },

          {
            key: '/goals',
            icon: <BarChartOutlined />,
            label: 'Goals / Milestones',
            onClick: () => navigate('/goals'),
          },
          {
            key: 'Performance',
            icon: <BarChartOutlined />,
            label: 'Performance',
            children: [
              {
                key: '/performance',
                icon: <DesktopOutlined />,
                label: 'Summary',
                onClick: () => navigate('/performance'),
              },
              {
                key: '/benchmarks',
                icon: <BarChartOutlined />,
                label: 'Vs. Benchmarks',
                onClick: () => navigate('/benchmarks'),
              },
            ],
          },
          {
            key: 'Public Market',
            icon: <DesktopOutlined />,
            label: 'Public Market',
            children: [
              {
                key: '/public-market',
                icon: <DesktopOutlined />,
                label: 'Overview',
                onClick: () => navigate('/public-market'),
              },
            ],
          },
          {
            key: 'Private Market',
            icon: <DesktopOutlined />,
            label: 'Private Market',
            children: [
              {
                key: '/private-market',
                icon: <DesktopOutlined />,
                label: 'Overview',
                onClick: () => navigate('/private-market'),
              },
              {
                key: '/updates',
                icon: <MessageOutlined />,
                label: 'Investment News',
                onClick: () => navigate('/updates'),
              },
              {
                key: '/checklist',
                icon: <CheckSquareOutlined />,
                label: 'K1 Checklist',
                onClick: () => navigate('/checklist'),
              },
            ],
          },
          {
            key: '/documents',
            icon: <BookOutlined />,
            label: 'Documents',
            onClick: () => navigate('/documents'),
          },
          {
            key: '/teams',
            icon: <TeamOutlined />,
            label: 'Team',
            onClick: () => navigate('/teams'),
          },
          {
            key: '/disclosures',
            icon: <BookOutlined />,
            label: 'Disclosures',
            onClick: () => navigate('/disclosures'),
          },
          {
            key: '/terms',
            icon: <FileOutlined />,
            label: 'Terms of Service',
            onClick: () => navigate('/terms'),
          },
        ]}
      />
    </>
  );
}

export default MenuList;
