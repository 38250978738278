import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Table from '@/components/Table';

import moment from 'moment';
import { IPublicHolding, IPublicHoldingProps } from './interface';

function PublicHolding(props: IPublicHoldingProps) {
  const { publicHoldings, isLoading } = props;
  const columns: ColumnsType<IPublicHolding> = [
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: '30%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity_display',
      key: 'quantity_display',
      sorter: (a, b) => a.quantity_raw - b.quantity_raw,
    },
    {
      title: 'Price',
      dataIndex: 'price_display',
      key: 'price_display',
      sorter: (a, b) => a.price_raw - b.price_raw,
    },
    {
      title: 'Amount',
      dataIndex: 'amount_display',
      key: 'amount_display',
      sorter: (a, b) => a.amount_raw - b.amount_raw,
      defaultSortOrder: 'descend',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          columns={columns}
          dataSource={publicHoldings}
          isLoading={isLoading}
          title={`Public Market Holdings (As of ${moment().format('MMMM DD, YYYY')})`}
          rowKey={(record) => record.position_id}
        />
      </Col>
    </Row>
  );
}

export default PublicHolding;
