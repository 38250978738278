import { useEffect, useRef, useState } from 'react';
import { Button, Col, Empty, Modal, Row, Space, Typography } from 'antd';
import { BorderOutlined, CheckSquareOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Card from '@/components/Card';
import Table from '@/components/Table';
import { theme } from '@/utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store';
import { downloadK1ChecklistFile, getK1CheckList } from '@/services/privateMarketService';
import { ColumnsType } from 'antd/lib/table';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { clearK1ChecklistErrorState } from '@/store/private-market/privateMarketSlice';
import CustomBreadcrumb from '@/components/Breadcrumb';
import { dollarCurrencyFormat } from '@/utils/helper';
import moment from 'moment';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { IK1CheckListPosition } from './interface';

export const columns: ColumnsType<IK1CheckListPosition> = [
  {
    title: 'Entities',
    dataIndex: 'investment_name',
    key: 'investment_name',
    width: '50%',
    render: (text: string, record: IK1CheckListPosition, index: number) => (
      <Space direction="horizontal" align="start">
        {record.document_id ? <CheckSquareOutlined /> : <BorderOutlined />}
        <Typography.Paragraph
          style={{ marginLeft: 5, color: theme['@link-color'], cursor: 'pointer' }}
          onClick={() =>
            record.document_id && downloadK1ChecklistFile({ documentId: record.document_id })
          }
        >
          {text}
        </Typography.Paragraph>
      </Space>
    ),
    sorter: (a, b) => a.investment_name.localeCompare(b.investment_name),
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Committed Amount',
    dataIndex: 'committed_amount',
    key: 'committed_amount',
    width: '50%',
    render: (value: number) => <span>{dollarCurrencyFormat(value)}</span>,
    sorter: (a, b) => a.committed_amount - b.committed_amount,
  },
];

function K1Checklist() {
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getK1CheckList());
  }, [dispatch]);

  const { data, isLoading, isError, errorMessage } = useSelector(
    (state: AppState) => state.privateMarket.k1CheckList
  );

  useShowErrorToast({ isError, errorMessage, clearErrorState: clearK1ChecklistErrorState });

  const videoRef = useRef<HTMLVideoElement>(null);

  const showModal = () => {
    setIsHelpModalVisible(true);
  };

  const handleCancel = () => {
    setIsHelpModalVisible(false);
    // stop the video on modal close
    if (videoRef.current != null) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const previousYear = moment().subtract(1, 'year').format('YYYY');
  if (isLoading) {
    return (
      <Card title="K1 CheckList" isLoading={isLoading}>
        <Empty />
      </Card>
    );
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <CustomHelmet title="K1 Checklist" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: (
              <>
                <CheckSquareOutlined />
                <span style={{ marginLeft: 10 }}>K1 Checklist</span>
              </>
            ),
          },
        ]}
      />

      {data.totalIlLiquid > 0 || data.totalNav > 0 ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button onClick={showModal} type="primary">
              <InfoCircleOutlined /> Need Help?
            </Button>
          </div>
          <Card title={`${previousYear} Schedule K-1 Forms`}>
            <Typography.Paragraph>
              {`Below are Schedule K-1 Tax Forms for the ${previousYear}  tax year. When a Schedule K-1 has been
                published and ready to be viewed/downloaded, you'll see a check mark in the box next
                to that investment account. Boxes without a checkmark are not yet available.`}
            </Typography.Paragraph>
          </Card>
          {Object.keys(data).length > 0 ? (
            Object.values(data.accounts).map(
              (item) =>
                item?.positions?.length > 0 && (
                  <Card title={item?.account_name} key={item?.account_name}>
                    <Row>
                      <Col span={24}>
                        <Table
                          className="hover-row"
                          isCardLayout={false}
                          dataSource={item.positions}
                          columns={columns}
                          pagination={false}
                          globalSearch={false}
                        />
                      </Col>
                    </Row>
                  </Card>
                )
            )
          ) : (
            <Card title="K1 CheckList" isLoading={isLoading}>
              <Empty />
            </Card>
          )}
        </>
      ) : (
        <Typography.Paragraph className="alert-info">
          Please discuss with your Personal Wealth Advisor if Private Market Investments are
          appropriate for you to achieve your financial goals.
        </Typography.Paragraph>
      )}
      <Modal
        forceRender={true}
        footer={null}
        title="Learn About the K-1 Checklist"
        open={isHelpModalVisible}
        onCancel={handleCancel}
        width={900}
        style={{ top: 20 }}
      >
        {isHelpModalVisible && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video style={{ width: 800 }} controls={true} autoPlay={true} ref={videoRef}>
            <source
              src="https://s3.amazonaws.com/bip-training-videos/k1checklist-tutorial.mp4"
              type="video/mp4"
            />
          </video>
        )}
      </Modal>
    </Space>
  );
}

export default K1Checklist;
