/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'antd';
import Card from '@/components/Card';
import Table from '@/components/Table';
import Footer from '@/components/Table/Footer';
import { IBdcPosition } from './interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderFooter(data: any, columns: any): any {
  const pageData = data as any;

  return (
    <Footer
      pageData={pageData}
      label="Total"
      sumFields={[
        { field: 'shares', value: 'shares', align: 'center' },
        {
          field: 'subscription_amount_display',
          displayFormat: 'currency',
          value: 'subscription_amount_raw',
          align: 'center',
        },
        { field: 'nav_display', displayFormat: 'currency', value: 'nav_raw', align: 'center' },
        {
          field: 'pending_display',
          displayFormat: 'currency',
          value: 'pending_raw',
          align: 'center',
        },
      ]}
      columns={columns}
    />
  );
}
function BDCPositions(props: {
  data: IBdcPosition[];
  isLoading: boolean;
  renderedBy?: string;
  title: string;
}) {
  const { data, isLoading, renderedBy, title } = props;
  const isPending = data?.some((item) => Boolean(item.pending_raw) && item.pending_raw > 0);

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'investment_name',
      key: 'investment_name',
      width: 150,
    },
    {
      title: 'ACCOUNT NAME',
      dataIndex: 'account_name',
      key: 'account_name',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'SHARES',
      dataIndex: 'shares',
      key: 'shares',
      align: 'center' as const,
      width: 50,
    },
    {
      title: 'PENDING',
      dataIndex: 'pending_display',
      key: 'pending_display',
      hidden: !isPending,
      align: 'center' as const,
      width: 50,
    },
    {
      title: 'NAV',
      dataIndex: 'nav_display',
      key: 'nav_display',
      align: 'center' as const,
      width: 50,
    },
    {
      title: 'SUBSCRIPTION AMOUNT',
      dataIndex: 'subscription_amount_display',
      key: 'subscription_amount_display',
      align: 'center' as const,
      width: 50,
    },
  ].filter((column) => !column.hidden);
  return (
    <Card title={title}>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            className="bg-header-white"
            isCardLayout={false}
            dataSource={data}
            columns={columns}
            pagination={false}
            globalSearch={false}
            summary={(pageData) => data.length > 0 && renderFooter(pageData, columns)}
            isLoading={isLoading}
            rowKey={(record) => record.investment_name}
            scroll={{ x: 'max-content' }}
            noDataMessage={
              renderedBy === 'Private Market Overview'
                ? `Please schedule time with your advisor to explore ${title?.replace(
                    /investments?/i,
                    ''
                  )} Investment opportunities.`
                : undefined
            }
          />
        </Col>
      </Row>
    </Card>
  );
}

BDCPositions.defaultProps = {
  renderedBy: '',
};

export default BDCPositions;
