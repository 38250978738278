import { useMemo } from 'react';
import { Col, Row, Skeleton } from 'antd';
import moment from 'moment';
import Card from '@/components/Card';
import PieChart from '@/components/Charts/Pie';
import { dollarCurrencyFormat } from '@/utils/helper';
import { IPublicAllocation, IPublicDataRowProps } from '../interface';
import './PublicAllocation.css';

interface AllocationProps {
  allocation: IPublicAllocation[];
  isLoading: boolean;
}

interface ReducedData {
  market_value_raw: number;
  percentage_raw: number;
}

function DataRow(props: IPublicDataRowProps) {
  const { columns } = props;
  const totalCalculation = useMemo(
    () =>
      columns.reduce(
        (accumulator: ReducedData, currentValue: IPublicAllocation) => ({
          market_value_raw: accumulator.market_value_raw + Number(currentValue.market_value_raw),
          percentage_raw: accumulator.percentage_raw + Number(currentValue.percentage_raw),
        }),
        { market_value_raw: 0, percentage_raw: 0 }
      ),
    [columns]
  );

  return (
    <div className="data-row">
      <div className="data-row-column">
        <div className="data-column">
          <strong>Public Assets</strong>
        </div>
        <div className="data-column">
          <strong>Market Value</strong>
        </div>
        <div className="data-column">
          <strong>Allocation %</strong>
        </div>
      </div>

      {columns.map((column: IPublicAllocation) => {
        const { name, market_value_display, percentage_display } = column;
        return (
          <div key={name} className="data-row-column">
            <p className="data-column">{name}</p>
            <p className="data-column">{market_value_display}</p>
            <p className="data-column">{percentage_display}</p>
          </div>
        );
      })}

      <div className="data-row-footer">
        <div className="data-row-column">
          <p className="data-column">
            <strong>Total</strong>
          </p>
          <p className="data-column">
            <strong>
              {dollarCurrencyFormat(Number(totalCalculation?.market_value_raw?.toFixed(2)))}
            </strong>
          </p>
          <p className="data-column">
            <strong>100%</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

function PublicAllocation({ allocation, isLoading }: AllocationProps) {
  return (
    <Card title={`My Public Market Overview (As of ${moment().format('MMMM DD, YYYY')})`}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row>
          <Col xs={24} lg={12}>
            <DataRow title="Public Assets" columns={allocation} />
          </Col>
          <Col xs={24} lg={12} style={{ height: 250 }}>
            <PieChart
              // showCustomPercent={true}
              data={allocation.map((item: IPublicAllocation) => ({
                type: item?.name,
                value: Number(item?.percentage_raw),
                tooltipValue: `${item?.percentage_display}`,
              }))}
              chartColors={[
                '#01313F',
                '#5EBF94',
                '#3AA59B',
                '#0C4125',
                '#0C5835',
                '#D5A021',
                '#4A051C',
              ]}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default PublicAllocation;
