import { Navigate } from 'react-router-dom';
import PublicMarket from '@/views/public-market';
import PortfolioDetailsPage from '@/views/public-market/portfolio-details';
import PrivateMarketDetails from '@/views/private-market/details';
import AccountDetailsPage from '@/views/public-market/account-details';
import GpUpdateDetail from '@/views/private-market/gp-updates/details';
// eslint-disable-next-line import/no-cycle
import MainLayout from '@/layout/MainLayout';
import PageNotFound from '@/views/404';
import Dashboard from '@/views/dashboard';
import TeamAndProfessional from '@/views/teams';
import GoalsAndMileStones from '@/views/goals';
import PrivateMarket from '@/views/private-market';
import GpUpdates from '@/views/private-market/gp-updates';
import K1Checklist from '@/views/private-market/k1-checklist';
import Disclosures from '@/views/disclosures';
import Terms from '@/views/terms-of-service';
import TwoFactorSetUp from '@/views/auth/2fa';
import DocumentsPage from '@/views/document';
import ChangePassword from '@/views/auth/change-password';
import PerformanceSummary from '@/views/performance/PerformanceSummary';
import Benchmarks from '@/views/performance/Benchmarks';
import PerformanceSummaryV2 from '@/views/performance/PerformanceSummary-v2';
import { PerformanceSummaryV2Page } from '@/views/performance/PerformanceSummary-v2page';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboard" />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/teams',
      element: <TeamAndProfessional />,
    },
    {
      path: '/goals',
      element: <GoalsAndMileStones />,
    },
    {
      path: '/public-market',
      element: <PublicMarket />,
    },
    {
      path: '/public-market/account-details/:id',
      element: <AccountDetailsPage />,
    },
    {
      path: '/portfolios/:id',
      element: <PortfolioDetailsPage />,
    },
    {
      path: '/private-market',
      element: <PrivateMarket />,
    },
    {
      path: '/private-market/:id',
      element: <PrivateMarketDetails />,
    },

    {
      path: '/updates',
      element: <GpUpdates />,
    },
    {
      path: '/updates/:id',
      element: <GpUpdateDetail />,
    },
    {
      path: '/checklist',
      element: <K1Checklist />,
    },
    {
      path: '/disclosures',
      element: <Disclosures />,
    },
    {
      path: '/documents',
      element: <DocumentsPage />,
    },
    {
      path: '/performance',
      element: <PerformanceSummaryV2Page />,
    },
    {
      path: '/performance-monthly',
      element: <PerformanceSummaryV2Page />,
    },
    {
      path: '/benchmarks',
      element: <Benchmarks />,
    },
    {
      path: '/terms',
      element: <Terms />,
    },
    { path: '/2fa-setup', element: <TwoFactorSetUp /> },
    { path: '/change-password', element: <ChangePassword /> },

    {
      path: '*',
      element: <PageNotFound />,
    },
  ],
};
export default MainRoutes;
