import { useState, useEffect } from 'react';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | ''>('');

  useEffect(() => {
    const mediaQueryLists = {
      xs: window.matchMedia('(max-width: 639px)'),
      sm: window.matchMedia('(min-width: 640px) and (max-width: 767px)'),
      md: window.matchMedia('(min-width: 768px) and (max-width: 1023px)'),
      lg: window.matchMedia('(min-width: 1024px) and (max-width: 1279px)'),
      xl: window.matchMedia('(min-width: 1280px) and (max-width: 1535px)'),
      '2xl': window.matchMedia('(min-width: 1536px)'),
    };

    const handleMediaQueryChange = () => {
      if (mediaQueryLists.xs.matches) {
        setScreenSize('xs');
      } else if (mediaQueryLists.sm.matches) {
        setScreenSize('sm');
      } else if (mediaQueryLists.md.matches) {
        setScreenSize('md');
      } else if (mediaQueryLists.lg.matches) {
        setScreenSize('lg');
      } else if (mediaQueryLists.xl.matches) {
        setScreenSize('xl');
      } else if (mediaQueryLists['2xl'].matches) {
        setScreenSize('2xl');
      }
    };

    Object.values(mediaQueryLists).forEach((mql) =>
      mql.addEventListener('change', handleMediaQueryChange)
    );
    handleMediaQueryChange();

    return () => {
      Object.values(mediaQueryLists).forEach((mql) =>
        mql.removeEventListener('change', handleMediaQueryChange)
      );
    };
  }, []);

  const isSmallScreen = screenSize === 'xs' || screenSize === 'sm';
  const isMediumScreen = screenSize === 'md';
  const isLargeScreen = screenSize === 'lg' || screenSize === 'xl' || screenSize === '2xl';

  return { screenSize, isSmallScreen, isMediumScreen, isLargeScreen };
};
