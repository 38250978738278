import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PieChart from '@/components/Charts/Pie';
import HouseholdAllocationSummary from '@/views/dashboard/HouseholdAllocationSummary';
import { getDashboard } from '@/services/dashboardService';
import { AppDispatch, AppState } from '@/store';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { clearGetDashboardErrorState } from '@/store/dashboard/dashboardSlice';
import CustomHelmet from '@/components/Helmet/CustomHelmet';

function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  const { dashboardData, isGetDashboardLoading, isGetDashboardError, getDashboardErrorMessage } =
    useSelector((state: AppState) => state.dashboard);

  const { tax_allocation = [], liquidity_allocation = [] } = dashboardData;

  useShowErrorToast({
    isError: isGetDashboardError,
    errorMessage: getDashboardErrorMessage,
    clearErrorState: clearGetDashboardErrorState,
  });

  return (
    <>
      <CustomHelmet title="Dashboard" />
      <Row>
        <Col span={24}>
          <HouseholdAllocationSummary
            isLoading={isGetDashboardLoading}
            dashboardData={dashboardData}
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <PieChart
            isCardLayout={true}
            title="PUBLIC INVESTMENTS VS. PRIVATE INVESTMENTS"
            data={liquidity_allocation.map((item) => ({
              type: item?.display_name,
              value: Number(item?.allocation_raw),
              tooltipValue: `${item?.allocation_display}`,
            }))}
            chartColors={['#55B9B7', '#023522']}
            isLoading={isGetDashboardLoading}
            showCustomPercent={true}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <PieChart
            isCardLayout={true}
            title="TAXABLE VS. TAX ADVANTAGED"
            data={tax_allocation.map((item) => ({
              type: item.display_name,
              value: Number(item?.allocation_raw),
              tooltipValue: `${item?.allocation_display}`,
            }))}
            chartColors={['#55B9B7', '#023522']}
            isLoading={isGetDashboardLoading}
            showCustomPercent={true}
          />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
