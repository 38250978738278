import { useEffect, useState } from 'react';
import { getApiErrorMessage } from '@/utils/helper';
import customNotification from '@/utils/notification';
import HttpClient from '../../utils/HttpClient';
import PerformanceSummaryV2 from './PerformanceSummary-v2';

export const PerformanceSummaryV2Page = () => {
  const [summaryData, setSummaryData] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [asOfReturnsDate, setAsOfReturnsDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchPerformanceSummaryData = async () => {
      try {
        setIsLoading(true);
        const response = await HttpClient.get('/monthly-performance-summary');
        setSummaryData(response?.data?.summaryData || []);
        setAsOfReturnsDate(response?.data?.asOfReturnsDate);
        setPeriods(response?.data?.periods || []);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        const errorMessage = getApiErrorMessage(e);
        customNotification({ type: 'error', message: errorMessage });
      }
    };
    fetchPerformanceSummaryData();
  }, []);

  return (
    <PerformanceSummaryV2
      summaryData={summaryData}
      periods={periods}
      asOfReturnsDate={asOfReturnsDate}
      isLoading={isLoading}
    />
  );
};
