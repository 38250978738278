import { Button, Col, List, Row, Space, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import Card from '@/components/Card';
import { theme } from '@/utils/theme';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store';
import { getGpUpdates } from '@/services/privateMarketService';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { clearGpUpdatesErrorState } from '@/store/private-market/privateMarketSlice';
import CustomBreadcrumb from '@/components/Breadcrumb';
import { MessageOutlined } from '@ant-design/icons';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { IGpUpdate, IRelatedInvestments } from './interface';
import GpUpdatesV2 from './gpupdates-v2';

function GpUpdates() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getGpUpdates());
  }, [dispatch]);

  const navigate = useNavigate();

  const { isGetGpUpdatesError, isGetGpUpdatesLoading, getGpUpdatesErrorMessage, data } =
    useSelector((state: AppState) => state?.privateMarket?.gpUpdates);

  useShowErrorToast({
    isError: isGetGpUpdatesError,
    errorMessage: getGpUpdatesErrorMessage,
    clearErrorState: clearGpUpdatesErrorState,
  });
  return (
    <>
      <CustomHelmet title="Private Investment Updates" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: (
              <>
                <MessageOutlined />
                <span style={{ marginLeft: 10 }}>Private Investment Updates</span>
              </>
            ),
          },
        ]}
      />
      {/* <GpUpdatesV2 data={data} /> */}
      <Row>
        <Col span={20}>
          <Card title="UPDATES FROM THE GENERAL PARTNERS" isLoading={isGetGpUpdatesLoading}>
            <List
              itemLayout="vertical"
              dataSource={data}
              size="small"
              renderItem={(item: IGpUpdate) => (
                <List.Item style={{ borderBottom: '3px solid #ccc' }}>
                  <List.Item.Meta
                    title={
                      <Link
                        to={`/updates/${item.update_id}`}
                        style={{ color: theme['@link-color'] }}
                      >
                        {item?.title}
                      </Link>
                    }
                    description={
                      <div>
                        <div>{item?.publish_date_display}</div>
                        <div>
                          Related Investments:
                          <span style={{ color: theme['@link-color'] }}>
                            {item?.related_investments?.map((investments: IRelatedInvestments) => (
                              <p key={investments.id}>
                                <Link
                                  key={investments.id}
                                  to={`/private-market/${investments.id}`}
                                  style={{ color: theme['@link-color'] }}
                                >
                                  {investments.name}
                                </Link>
                              </p>
                            ))}
                          </span>
                        </div>
                      </div>
                    }
                  />
                  <Space
                    direction="horizontal"
                    size="small"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography.Paragraph>{item?.excerpt_display}</Typography.Paragraph>
                    <Button
                      key={item.update_id}
                      type="primary"
                      onClick={() => navigate(`/updates/${item.update_id}`)}
                    >
                      Read More
                    </Button>
                  </Space>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default GpUpdates;
